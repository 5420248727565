<template>
  <div class="jumbotron p-5 rounded-lg">
    <h1 class="display-4">Databases</h1>
  </div>

  <div class="container text-center">
    <div class="row">
      <div class="col-md-4">
                <span class="fa-stack fa-4x">
                    <a href="https://mel.org/az.php" target="_blank">
                        <i class="fa fa-square fa-stack-2x" style="color:red"></i>
                        <i class="fa fa-user fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                    </a>
                  </span>
        <h2>MeL</h2>
        <p>Browse through the many MeL databases</p>
      </div>
      <div class="col-md-4">
        <span class="fa-stack fa-4x">
                    <a href="http://www.merckmanuals.com/?utm_source=RauchholzMemorialLibrary&utm_medium=Partner&utm_campaign=Linking" target="_blank">
                        <i class="fa fa-square fa-stack-2x" style="color:orangered"></i>
                        <i class="fa fa-user-md fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                    </a>
                  </span>
        <h2>Merck Manual</h2>
        <p>Explore the Merck Manual</p>
      </div>
      <div class="col-md-4">
                <span class="fa-stack fa-4x">
                    <a href="http://www.merckvetmanual.com/?utm_source=RauchholzMemorialLibrary&utm_medium=Partner&utm_campaign=Linking" target="_blank">
                        <i class="fa fa-square fa-stack-2x" style="color:orange"></i>
                        <i class="fa fa-paw fa-stack-1x fa-inverse faa-tada animated-hover"></i>
                    </a>
                  </span>
        <h2>Merck Vet Manual</h2>
        <p>Explore the Merck Veterinary Manual</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Databases'
}
</script>

<style scoped>
.jumbotron {
  /*background-color: #4e6738;*/
  background-color: #7a9c4e;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 2em
}

i {
  margin-bottom: .2em;
}
</style>
